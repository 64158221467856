import React from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';


export default function CustomNav({ theKey }) {
    const navigate = useNavigate();

    return (
        <Nav justify variant="pills" activeKey={theKey} defaultActiveKey={theKey} onSelect={(selectedKey) => navigate(selectedKey === 'home' ? '/' : `/${selectedKey}`) } className="my-5">
            <Nav.Item className="mx-3">
                <Nav.Link eventKey="home">Mint</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item className="mx-3">
                <Nav.Link eventKey="whitelist">Whitelist</Nav.Link>
            </Nav.Item>
            <Nav.Item className="mx-3">
                <Nav.Link eventKey="admin">Admin</Nav.Link>
            </Nav.Item> */}
        </Nav>
    );
}
