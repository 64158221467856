import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

import CustomNav from './components/CustomNav';
import ErrorGate from './components/ErrorGate';
import { WalletContext } from './providers/WalletProvider';
import { ETH_SEND_TRANSACTION } from './helpers/constants';
import {
    checkWhitelist,
    whitelistSaleIsActive,
    allowedPremiumMintCount,
    allowedStandardMintCount,
    maxStandard,
    maxPremium,
    mintPremiumWhitelist,
    mintStandardWhitelist
} from './helpers/web3';


export default function Whitelist() {
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [whitelistSig, setWhitelistSig] = useState('');
    const [verified, setVerified] = useState(false);
    const [totalPremium, setTotalPremium] = useState(0);
    const [totalStandard, setTotalStandard] = useState(0);
    const [maxPremiumCount, setMaxPremiumCount] = useState(1);
    const [maxStandardCount, setMaxStandardCount] = useState(1);
    const [allowedPremium, setAllowedPremium] = useState(1);
    const [allowedStandard, setAllowedStandard] = useState(1);
    
    const { config, setConfig, error, setError, onConnect, resetApp } = useContext(WalletContext);

    useEffect(() => {
        if (config.web3 && config.address !== '') {
            const _fetch = async () => {
                setLoading(true);
                setConfig((oldConfig) => ({...oldConfig, result: null}));

                try {
                    const whitelistSaleIsActiveResult = await whitelistSaleIsActive(config.chainId, config.web3);
                    setIsActive(whitelistSaleIsActiveResult);

                    const maxPremiumResult = await maxPremium(config.chainId, config.web3);
                    setMaxPremiumCount(parseInt(maxPremiumResult));

                    const maxStandardResult = await maxStandard(config.chainId, config.web3);
                    setMaxStandardCount(parseInt(maxStandardResult));

                    const allowedPremiumResult = await allowedPremiumMintCount(config.address, config.chainId, config.web3);
                    setAllowedPremium(parseInt(allowedPremiumResult));

                    const allowedStandardResult = await allowedStandardMintCount(config.address, config.chainId, config.web3);
                    setAllowedStandard(parseInt(allowedStandardResult));

                    setError(null);
                }
                catch (err) {
                    console.log('this do be error');
                    console.error(err);
                    setError(err);
                }

                setLoading(false);
            };
            _fetch();
        }
    }, [config.address, config.web3, config.chainId, setConfig, setError]);

    const startCheckWhitelist = async () => {
        const { web3, chainId, address } = config;
    
        if (!web3) {
            return;
        }
    
        if (!checkWhitelist) {
            throw new Error('Missing matching contract calls');
        }
    
        try {
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));
    
            const result = await checkWhitelist(whitelistSig, address, chainId, web3);

            if (result) {
                setVerified(result);
                setConfig((oldConfig) => ({
                    ...oldConfig,
                    web3,
                    pendingRequest: false
                }));
            }
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    const startSendTransaction = async (transactionType) => {
        const { web3, address, chainId } = config;

        if (!web3) {
            return;
        }

        if (!mintPremiumWhitelist || !mintStandardWhitelist) {
            throw new Error('Missing matching contract calls');
        }


        try {            
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));

            let result;
            if (transactionType === 'premium') {
                result = await mintPremiumWhitelist(`${totalPremium}`, whitelistSig, address, chainId, web3);
            }
            else if (transactionType === 'standard') {
                result = await mintStandardWhitelist(`${totalStandard}`, whitelistSig, address, chainId, web3);
            }

            const formattedResult = {
                action: ETH_SEND_TRANSACTION,
                result
            };

            setConfig((oldConfig) => ({
                ...oldConfig,
                web3,
                pendingRequest: false,
                result: formattedResult || null
            }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    return (
        <div className="App-header">
            <Container className="mt-3">
                <div>
                    {
                        config.address !== '' ? <div><Button onClick={resetApp} variant="link">Reset connection</Button></div> : null
                    }
                    <Link className="fs-6 mb-3 d-inline-block" to="/login">Login</Link>
                    <h6 style={{fontWeight: '700'}}>Your Address</h6>
                    <h6>{config.address === '' ? 'Connect your wallet to get started.' : config.address}</h6>
                </div>
                <CustomNav theKey="whitelist" />
                {
                    config.pendingRequest || loading ? 
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    :
                    <>
                        {
                            error ? <ErrorGate /> :
                                config.address === '' ? 
                                    <div className="mt-5">
                                        <Button variant="primary" onClick={onConnect}>
                                            Connect Wallet
                                        </Button>
                                    </div>
                                :
                                    isActive ? 
                                        verified ? 
                                            config.result ? 
                                                <>
                                                    <h6 style={{fontWeight: 700}}>You're on your way! Here is your transaction reciept...</h6>
                                                    <h6><a href={`https://rinkeby.etherscan.io/tx/${config.result.result.transactionHash}`} rel="noreferrer" target="_blank">{config.result.result.transactionHash}</a></h6>
                                                </>
                                            :
                                                <Row>
                                                    <Col>
                                                        <Card className="text-dark">
                                                            <Card.Header>
                                                                <Card.Title>Premium Token</Card.Title>
                                                                <h6>Total supply: {maxPremiumCount}</h6>
                                                                <h6>Available to mint: {allowedPremium}</h6>
                                                                <h6>0.069 ETH + GAS</h6>
                                                            </Card.Header>
                                                            <Card.Body className="d-flex align-items-center justify-content-center">
                                                                {

                                                            
                                                                    allowedPremium === 0 ?
                                                                        <h6>You have already minted the max number of standard tokens allowed.</h6>
                                                                    :
                                                                        <>
                                                                            <Button onClick={() => setTotalPremium(totalPremium + 1)}>
                                                                                +
                                                                            </Button>
                                                                            <span className="px-5">{totalPremium}</span>
                                                                            <Button disabled={totalPremium <= 0 ? true : false} onClick={() => setTotalPremium(totalPremium - 1)}>
                                                                                -
                                                                            </Button>
                                                                        </>
                                                                }
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Button disabled={totalPremium > 0 ? false : true} onClick={() => startSendTransaction('premium')}>
                                                                    {
                                                                        allowedPremium === 0 ? 'Max minted' : 'Mint'
                                                                    }
                                                                </Button>
                                                            </Card.Footer>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card className="text-dark">
                                                            <Card.Header>
                                                                <Card.Title>Standard Token</Card.Title>
                                                                <h6>Total supply: {maxStandardCount}</h6>
                                                                <h6>Available to mint: {allowedStandard}</h6>
                                                                <h6>FREE + GAS</h6>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                {
                                                                    allowedStandard === 0 ?
                                                                        <h6>You have already minted the max number of standard tokens allowed.</h6>
                                                                    :
                                                                        <>
                                                                            <Button onClick={() => setTotalStandard(totalStandard + 1)}>
                                                                                +
                                                                            </Button>
                                                                            <span className="px-5">{totalStandard}</span>
                                                                            <Button disabled={totalStandard <= 0 ? true : false} onClick={() => setTotalStandard(totalStandard - 1)}>
                                                                                -
                                                                            </Button>
                                                                        </>
                                                                }
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <Button disabled={totalStandard > 0 ? false : true} onClick={() => startSendTransaction('standard')}>
                                                                    {
                                                                        allowedStandard === 0 ? 'Max minted' : 'Mint'
                                                                    }
                                                                </Button>
                                                            </Card.Footer>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                        :
                                            <div className="mt-0 mx-auto" style={{maxWidth: '600px'}}>
                                                <h6 className="mb-3" style={{fontWeight: '700'}}>Verify Whitelist Access</h6>
                                                <Form.Control type="text" className="my-3" value={whitelistSig} onChange={(e) => setWhitelistSig(e.target.value)} />
                                                <Button className="mt-3" onClick={startCheckWhitelist}>
                                                    Verify
                                                </Button>
                                            </div>
                                    :
                                        <>
                                            <h6>The whitelist sale is currently closed.</h6>
                                        </>
                        }
                    </>
                }
            </Container>
        </div>
    );
}
