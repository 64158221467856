import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyDwtqpoH9GO06wjuUwkmLcQxV6Ib2pkPtk",
    authDomain: "mighty-samo.firebaseapp.com",
    projectId: "mighty-samo",
    storageBucket: "mighty-samo.appspot.com",
    messagingSenderId: "973513387157",
    appId: "1:973513387157:web:6c3449d27cb2b9aa58ff9c",
    measurementId: "G-RYX62BKEJ0"  
};
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
