import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/esm/Spinner';
import axios from 'axios';
import { Link } from 'react-router-dom';

// import { db } from '../helpers/base';
import { getPastEvents } from '../helpers/web3';
import ErrorGate from './ErrorGate';


export default function LoadingCard({ transactionHash, web3, signature, chainId }) {
    const [receipt, setReceipt] = useState({});
    const [confirmations, setConfirmations] = useState(0);
    // const [isUploaded, setIsUploaded] = useState(false);
    const [values, setValues] = useState({});
    const [error, setError] = useState(null);

    // useEffect(() => {
    //     const upload = async () => {
    //         try {
    //             if (Object.keys(values).length > 0) {
    //                 const result = await axios.post('/api/metadata', { tokenId: values._tokenId, dataId: values._dataId });
    //                 const totalTokensResult = await axios.post(`/api/${values._dataId}/totalTokens`);
    //                 console.log('FILE RESULT', result.data, 'TOT TOKENS RESULT', totalTokensResult.data);
    //                 setIsUploaded(true);
    //             }
    //         }
    //         catch(e) {
    //             console.log('No upload', e);
    //         }
    //     };
    //     upload();
    // }, [values]);

    useEffect(() => {
        let timeout = setInterval(async () => {
            try {
                console.log('three seconds', transactionHash);
                if (transactionHash !== '' && transactionHash !== null) {
                    const transactionReceipt = await web3.eth.getTransactionReceipt(transactionHash);
                    const transactionBlockNumber = await web3.eth.getTransaction(transactionHash);
                    const currentBlockNumber = await web3.eth.getBlockNumber();

                    if (transactionReceipt !== null) {
                        setReceipt(transactionReceipt);
                    }
                    
                    if (transactionReceipt !== null && transactionBlockNumber.blockNumber !== null) {
                        const _confirmations = currentBlockNumber - transactionBlockNumber.blockNumber;
                        setConfirmations(_confirmations);

                        console.log('confirmations tx:', transactionReceipt, currentBlockNumber, transactionBlockNumber.blockNumber, _confirmations);

                        if (_confirmations >= 2) {
                            clearInterval(timeout);

                            const events = await getPastEvents(signature, transactionBlockNumber.blockNumber, chainId, web3);
                            setValues(events[0].returnValues);
                            console.log('Contract Events', events[0].returnValues, events);
                        }
                    }
                }
                else {
                    console.log('Oh no!');
                }
            }
            catch(e) {
                clearInterval(timeout);
                console.log('Oh no!', e);
                setError(e);
            }
        }, 3000);

        return () => {
            console.log('unmounted');
            clearInterval(timeout);
        };
    }, [chainId, signature, transactionHash, web3]);

    useEffect(() => {
        const queue = async () => {
            try {
                if (transactionHash !== '' && transactionHash !== null) {
                    const metadataResult = await axios.post('/api/metadata/queue', { signature, transactionHash });
                    console.log('Metadata result', metadataResult.data);
                }
            }
            catch (e) {
                console.log('Error', e);
            }
        };
        queue();
    }, [transactionHash, signature]);

    return (
        <>
            {
                error !== null ? 
                    <ErrorGate />
                :
                    <Card className="p-4 mx-auto my-5" style={{maxWidth: '600px'}}>
                        <Card.Title className="text-dark" style={{fontSize: '1.75rem', fontWeight: 'bold'}}>You are on your way! Your transaction is being confirmed...</Card.Title>
                        <Card.Body>
                            <h6 className="text-dark">Transaction Hash</h6>
                            <h6 className="text-dark">{transactionHash}</h6>

                            <a href={`https://rinkeby.etherscan.io/tx/${transactionHash}`} target="_blank" rel="noreferrer" style={{fontSize: '1rem'}}>View on block explorer</a>
                            
                            <div style={{maxWidth: '300px'}} className="mt-4 mx-auto border rounded border-dark p-3">
                                <h5 className="text-start text-dark" style={{fontWeight: '600'}}>Progress</h5>
                                <h6 className="text-dark d-flex justify-content-between align-items-center">
                                    <span>Waiting for receipt...</span>
                                    <span>
                                        {
                                            Object.keys(receipt).length > 0 ? 'Done!' :
                                            <Spinner animation="border" role="status" size="sm">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        }
                                    </span>
                                </h6>
                                <h6 className="text-dark d-flex justify-content-between align-items-center">
                                    <span>Confirmation 1...</span>
                                    <span>
                                        {
                                            confirmations >= 1 ? 'Done!' :
                                            <Spinner animation="border" role="status" size="sm">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        }
                                    </span>
                                </h6>
                                <h6 className="text-dark d-flex justify-content-between align-items-center">
                                    <span>Confirmation 2...</span>
                                    <span>
                                        {
                                            confirmations >= 2 ? 'Done!' :
                                            <Spinner animation="border" role="status" size="sm">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        }
                                    </span>
                                </h6>
                            </div>
            
                            {
                                confirmations >= 2 && Object.keys(values).length > 0 && (
                                    <div>
                                        <Card.Title className="text-dark mt-4" style={{fontSize: '1.75rem', fontWeight: 'bold'}}>Your token is ready!</Card.Title>
                                        <div>
                                            <a href={`https://testnets.opensea.io/assets/rinkeby/${process.env.REACT_APP_CONTRACT_ADDRESS}/${values._tokenId}`} target="_blank" rel="noreferrer" style={{fontSize: '1rem'}} className="mx-3">View on Opensea</a>
                                            <Link to="/my-tokens" style={{fontSize: '1rem'}} className="mx-3">View My Tokens</Link>
                                            {/* <Link to="/my-tokens" style={{fontSize: '1rem'}} className="mx-3">Add To MetaMask</Link> */}
                                        </div>
                                    </div>
                                )
                            }
                        </Card.Body>
                    </Card>
            }
        </>
    );
}
