import React, { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Web3 from 'web3';

import LinkNav from './components/LinkNav';
import { WalletContext } from './providers/WalletProvider';
import { ownerOf, tokenURI, verifyTokenFileSigner, signFileSignatureToken } from './helpers/web3';


export default function Verify() {
    const { config, resetApp } = useContext(WalletContext);
    const [tokenId, setTokenId] = useState('');
    const [owner, setOwner] = useState('');
    const [metadata, setMetadata] = useState({});
    const [documentId, setDocumentId] = useState('');
    const [signature, setSignature] = useState('');

    const startVerify = async () => {
        try {
            if (tokenId > -1 && tokenId !== '') {
                const _owner = await ownerOf(tokenId, config.chainId, config.web3);
                console.log('V OWNER', owner);
                setOwner(_owner);
    
                const _tokenURI = await tokenURI(tokenId, config.chainId, config.web3);
                console.log('V TOKEN URI', _tokenURI);
    
                const res = await axios.get(_tokenURI);
                console.log('V METADATA', res.data);
                setMetadata(res.data);
    
                if (res.data && res.data.attributes) {
                    const index = res.data.attributes.map((a) => a.trait_type).indexOf('Validation ID');
                    setDocumentId(res.data.attributes[index].value);
                }
            }
        }
        catch (err) {
            console.error(err);
        }
    };

    const startFileSign = async () => {
        try {
            const _signature = await signFileSignatureToken(metadata.name, metadata, documentId, config.chainId, config.web3, config.ethers);
            console.log('V SIGNATURE', _signature);
            setSignature(_signature);

            const _isSigner = await verifyTokenFileSigner(metadata.name, metadata, documentId, _signature, config.address, config.chainId, config.web3);
            console.log('V IS SIGNER', _isSigner);
        }
        catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="App-header">
            <Container className="mt-3 mb-5">
                <LinkNav address={config.address} resetApp={resetApp} chainId={config.chainId} />
                
                {
                    owner === '' ? 
                        <Card className="my-5 mx-auto" style={{maxWidth: '600px'}}>
                            <Card.Header>
                                <Card.Title className="text-dark my-3" style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Verify Ownership</Card.Title>
                            </Card.Header>
                            <Card.Body style={{maxWidth: '500px'}} className="mx-auto text-start">
                                <Form>
                                    <Form.Group className="mb-3 text-dark" controlId="formTokenId">
                                        <Form.Label>Token ID</Form.Label>
                                        <Form.Control type="number" placeholder="" value={tokenId} onChange={(e) => setTokenId(e.target.value)} />
                                        <Form.Text className="text-muted">
                                            The token id can be found under My Tokens
                                        </Form.Text>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                            <Card.Footer>
                                <Button className="my-4" style={{fontWeight: '700', fontSize: '1rem', letterSpacing: '0.03em', minWidth: '120px'}} onClick={() => startVerify()}>
                                    Continue
                                </Button>
                            </Card.Footer>
                        </Card>
                    :
                        signature === '' ? 
                            <Card className="my-5 mx-auto" style={{maxWidth: '600px'}}>
                                <Card.Header>
                                    <Card.Title className={`my-3 ${owner !== '' && owner === config.address ? 'text-dark' : 'text-danger'}`} style={{fontWeight: '700'}}>
                                        {
                                            owner !== '' && owner === config.address ? 'You are the owner.' : 'You are NOT the owner.'
                                        }
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="mx-auto text-dark text-start" style={{maxWidth: '500px'}}>
                                    <p className="text-dark mt-4">You are being asked to sign your intent data to prove your wallet is the work's owner. The signed data will be used to verify your purchase and mint.</p>

                                    <div>
                                        <Card.Title className="mt-5 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                                            Name
                                        </Card.Title>
                                        <h6 className="text-start mt-2" style={{fontWeight: '600'}}>{metadata.name}</h6>
                                        <p className="text-start">This field represents the name of the work as a human readable identifier.</p>
                                    </div>

                                    <div>
                                        <Card.Title className="mt-5 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                                            Metadata Hash
                                        </Card.Title>
                                        <h6 className="text-start mt-2" style={{fontWeight: '600'}}>{Web3.utils.soliditySha3(JSON.stringify(metadata))}</h6>
                                        <p className="text-start">This field is a SHA3 hash of the metadata which represents the work and underlying token. This metadata is what's seen on OpenSea, marketplaces, and other wallet managers. The hash of the stored metadata is an additional safeguard to establish authenticity of the owner(s) and transactions.</p>
                                    </div>

                                    <div className="mb-4">
                                        <Card.Title className="mt-5 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                                            Data ID
                                        </Card.Title>
                                        <h6 className="text-start mt-2" style={{fontWeight: '600'}}>{documentId}</h6>
                                        <p className="text-start">This field represents the work's unique attributes hosted on and off chain. This identifier may appear physically on the work, in addition to representing the work's properties across all digital platforms. It is included in the signature to provide a concrete record associating your wallet and the work wherever ownership may need verified either online or in real life.</p>
                                    </div>
                                </Card.Body>
                                <Card.Footer>
                                    <Button className="my-4" style={{fontWeight: '700', fontSize: '1rem', letterSpacing: '0.03em', minWidth: '120px'}} onClick={() => startFileSign()}>
                                        Sign and Continue
                                    </Button>
                                </Card.Footer>
                            </Card>
                        :
                            <Card className="my-5 mx-auto" style={{maxWidth: '600px'}}>
                                <Card.Body>
                                    <Card.Title className={`my-3 ${owner !== '' && owner === config.address ? 'text-success' : 'text-danger'}`} style={{fontWeight: '700'}}>
                                        { owner !== '' && owner === config.address ? 'Your ownership has been successfully verified!' : 'Your ownership verification has failed.' }
                                    </Card.Title>
                                </Card.Body>
                            </Card>
                }
                
            </Container>
        </div>
    );
}
