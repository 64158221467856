import React,{ useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import Web3 from 'web3';
import { doc, getDoc } from 'firebase/firestore';
import Spinner from 'react-bootstrap/esm/Spinner';

import { db } from '../helpers/base';


export default function TokenTemplateCard({ id, startSendTransaction }) {
    const [maxSupply, setMaxSupply] = useState(0);
    const [totalTokens, setTotalTokens] = useState(0);
    const [mintPrice, setMintPrice] = useState('0');
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [artistName, setArtistName] = useState('');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const _fetch = async () => {
            if (id !== '') {
                setIsLoading(true);
                const docRef = doc(db, "templatesData", id);
                const docSnap = await getDoc(docRef);
                console.log('doc snap', docSnap, docSnap.exists());
    
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    console.log("Document data:", data);
                    setMaxSupply(data.maxSupply);
                    setTotalTokens(data.totalTokens);
                    setMintPrice(data.priceInWei);
                    setImage(data.image);
                    setArtistName(data.artistName);
                    setDescription(data.description);
                    setName(data.name);
                }
                else {
                    console.log("No template data document!");
                }

                const templateRef = doc(db, "templates", id);
                const templateSnap = await getDoc(templateRef);
    
                if (templateSnap.exists()) {
                    const data = templateSnap.data();
                    setTemplate(data);
                    console.log('Template data:', data);
                }
                else {
                    console.log("No template document!");
                }
                setIsLoading(false);
            }
        };
        _fetch();
    }, [id]);

    return (
        <Card className="text-dark mx-auto my-3" style={{maxWidth: '600px'}}>
            <Card.Body style={{maxWidth: '500px'}} className="mx-auto">
                {
                    isLoading ? 
                        <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    :
                        <div className="mt-2 mb-4">
                            <Card.Title className="mb-2" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>{name}</Card.Title>
                            <h6 className="mt-2 mb-4" style={{fontWeight: '600'}}>{Web3.utils.fromWei(mintPrice, 'ether')} ETH + GAS</h6>
                            <div className="border border-dark rounded p-2 bg-dark my-4">
                                <img alt={name} src={image} width="500px" style={{maxWidth: '100%'}} className="rounded" />
                            </div>
                            <div>
                                <Card.Title className="mb-2 text-start" style={{fontWeight: 'bold'}}>by {artistName}</Card.Title>
                                <p className="text-start" style={{fontSize: '1rem'}}>{description}</p>
                            </div>
                            <h6 className="text-start my-4" style={{fontWeight: 'bold'}}><span className="d-inline-block me-3">Total supply: {maxSupply}</span><span className="d-inline-block">Total minted: {totalTokens}</span></h6>
                        </div>
                }
            </Card.Body>
            <Card.Footer>
                <Button className="my-4" disabled={totalTokens >= maxSupply ? true : false} style={{fontWeight: '700', fontSize: '1rem', letterSpacing: '0.03em', minWidth: '120px'}} onClick={() => startSendTransaction(id, template, name, mintPrice)}>
                    {
                        totalTokens >= maxSupply ? 'Max minted' : 'Mint'
                    }
                </Button>
            </Card.Footer>
        </Card>
    );
}
