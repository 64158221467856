import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import supportedChains from '../helpers/chains';


export default function LinkNav({ address, resetApp, chainId }) {
    const [chainName, setChainName] = useState('');

    useEffect(() => {
        if (chainId > -1) {
            const index = supportedChains.map((c) => c.chain_id).indexOf(chainId);
            if (index > -1) {
                setChainName(supportedChains[index].name)
            }
        }
    }, [chainId]);

    return (
        <div>
            <Link className="btn btn-link fs-6 d-inline-block m-3" to="/">Mint</Link>
            <Link className="btn btn-link fs-6 d-inline-block m-3" to="/my-tokens">My Tokens</Link>
            <Link className="btn btn-link fs-6 d-inline-block m-3" to="/verify">Verify</Link>
            {
                address !== '' ? <button type="button" onClick={resetApp} className="btn btn-link fs-6 d-inline-block mx-3 p-0">Reset connection</button> : null
            }
            <div className="my-4">
                <h6 style={{fontWeight: '700'}}>Your Address</h6>
                <h6>{address === '' ? 'Connect your wallet to get started.' : address}</h6>
            </div>
            <div className="my-4">
                <h6 style={{fontWeight: '700'}}>Your Network</h6>
                <h6>{chainName === '' ? 'Connect your wallet to get started.' : chainName}</h6>
            </div>
        </div>
    );
}
