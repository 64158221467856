import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { refreshPage } from '../helpers/utilities';


export default function ErrorGate() {
    return (
        <div className="my-5">
            <Card className="p-4 mx-auto" style={{maxWidth: '600px'}}>
                <Card.Body>
                    <h5 className="text-dark">Oh no! This was an error!</h5>
                    <Button className="mt-0" onClick={() => refreshPage()}>Refresh page</Button>
                </Card.Body>
            </Card>
        </div>
    );
}
