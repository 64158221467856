import React, { createContext, useState, useCallback, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import { auth } from "../helpers/base";


export const AuthContext = createContext({
    currentUser: null,
    setCurrentUser: () => {},
    pending: true,
    setPending: () => {}
});

export const AuthProvider = ({ children }) => {
    const [currentUser, _setCurrentUser] = useState(null);
    const [pending, _setPending] = useState(true);

    const setCurrentUser = useCallback((user) => _setCurrentUser(user), [_setCurrentUser]);
    const setPending = useCallback((p) => _setPending(p), [_setPending]);

    useEffect(() => {
        console.log('loading auth provider');
        auth.onAuthStateChanged((user) => {
            console.log('on auth state changes', user);
            setCurrentUser(user);
            setPending(false);
        });
    });

    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser, pending, setPending }}>
            {
                pending ? 
                    <div className="App-header">
                        <Container className="mt-5">
                            <p>The container is loading...</p>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Container>
                    </div>
                : 
                    children
            }
        </AuthContext.Provider>
    );
};
