import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Web3 from 'web3';


export default function ConfirmationCard({ mintPrice, onClick, document, signature }) {
    return (
        <div className="my-5">
            <Card className="text-dark mx-auto my-3" style={{maxWidth: '600px'}}>
                <Card.Header>
                    <Card.Title className="my-3" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                        Confirm and Pay
                    </Card.Title>
                </Card.Header>
                <Card.Body style={{maxWidth: '500px'}} className="mx-auto text-start">
                    <p className="text-start mt-3">Please do not close this window. Your progress will not be saved.</p>

                    <p className="text-start">Below you will be asked to confirm your minting transaction with your unique intent signature.</p>

                    <div>
                        <Card.Title className="mt-4 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>{document.name}</Card.Title>
                        <h6 className="text-start mt-2 mb-0" style={{fontWeight: '600'}}>{Web3.utils.fromWei(mintPrice, 'ether')} ETH + GAS</h6>
                    </div>
                    <div className="border border-dark rounded p-2 bg-dark my-3 d-inline-block">
                        <img alt={document.name} src={document.image} width="500px" style={{maxWidth: '100%', maxHeight: '200px', width: 'auto'}} className="rounded" />
                    </div>
                    <div className="mb-3">
                        <Card.Title className="mt-4 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>Your Intent Signature</Card.Title>
                        <h6 className="text-start mt-2" style={{fontWeight: '600'}}>{signature}</h6>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Button className="my-4" style={{fontWeight: '700', fontSize: '1rem', letterSpacing: '0.03em', minWidth: '120px'}} onClick={() => onClick()}>
                        Confirm and Continue
                    </Button>
                </Card.Footer>
            </Card>
        </div>
    );
}
