import React from 'react';
import Web3 from 'web3';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


export default function SigningCard({ mintPrice, onClick, document, documentId }) {
    return (
        <div className="my-5">
            <Card className="text-dark mx-auto my-3" style={{maxWidth: '600px'}}>
                <Card.Header>
                    <Card.Title className="my-3" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                        Verify Mint
                    </Card.Title>
                </Card.Header>
                <Card.Body style={{maxWidth: '500px'}} className="mx-auto text-start">
                    <p className="text-start mt-3">Please do not close this window. Your progress will not be saved.</p>

                    <p className="text-start">Continue below to create your minting signature.</p>

                    <div>
                        <Card.Title className="mt-4 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                            {document.name}
                        </Card.Title>
                        <h6 className="text-start mt-2 mb-0" style={{fontWeight: '600'}}>{Web3.utils.fromWei(mintPrice, 'ether')} ETH + GAS</h6>
                        <div className="border border-dark rounded p-2 bg-dark my-3 d-inline-block">
                            <img alt={document.name} src={document.image} width="500px" style={{maxWidth: '100%', width: 'auto', maxHeight: '200px'}} className="rounded" />
                        </div>
                    </div>

                    <p className="text-start mt-4">You are being asked to sign the following data fields described below. This signature represents your intent to purchase, mint, and own this work. Once signed, this data will be used to verify your ownership and prove that you are the rightful receipient of the work's physical and digital assets. The signature conforms to the EIP-712 standard.</p>

                    <div>
                        <Card.Title className="mt-4 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                            Name
                        </Card.Title>
                        <h6 className="text-start mt-2" style={{fontWeight: '600'}}>{document.name}</h6>
                        <p className="text-start">This field represents the name of the work as a human readable identifier.</p>
                    </div>

                    <div>
                        <Card.Title className="mt-4 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                            Metadata Hash
                        </Card.Title>
                        <h6 className="text-start mt-2" style={{fontWeight: '600'}}>{Web3.utils.soliditySha3(JSON.stringify(document))}</h6>
                        <p className="text-start">This field is a SHA3 hash of the metadata which represents the work and underlying token. This metadata is what's seen on OpenSea, marketplaces, and other wallet managers. The hash of the stored metadata is an additional safeguard to establish authenticity of the owner(s) and transactions.</p>
                    </div>

                    <div className="mb-4">
                        <Card.Title className="mt-4 mb-2 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                            Data ID
                        </Card.Title>
                        <h6 className="text-start mt-2" style={{fontWeight: '600'}}>{documentId}</h6>
                        <p className="text-start">This field represents the work's unique attributes hosted on and off chain. This identifier may appear physically on the work, in addition to representing the work's properties across all digital platforms. It is included in the signature to provide a concrete record associating your wallet and the work wherever ownership may need verified either online or in real life.</p>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Button className="my-4" style={{fontWeight: '700', fontSize: '1rem', letterSpacing: '0.03em', minWidth: '120px'}} onClick={() => onClick()}>
                        Sign and Continue
                    </Button>
                </Card.Footer>
            </Card>
        </div>
    );
}
