import React,{ useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import axios from 'axios';
import Spinner from 'react-bootstrap/esm/Spinner';

// import { db } from '../helpers/base';
import { ownerOf, tokenURI } from '../helpers/web3';


export default function TokenCard({ id, chainId, web3, address }) {
    const [metadata, setMetadata] = useState({});
    const [_ownerOf, setOwnerOf] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const _fetch = async () => {
            try {
                if (id !== '') {
                    setIsLoading(true);
                    const _owner = await ownerOf(id, chainId, web3);
                    const _tokenURI = await tokenURI(id, chainId, web3);
                    const res = await axios.get(_tokenURI);
                    console.log('METADATA', res.data);
                    setMetadata(res.data);
                    setOwnerOf(_owner);
                    setIsLoading(false);
                }
            }
            catch(e) {
                console.log('Error!', e);
            }
        };
        _fetch();
    }, [id, chainId, web3]);

    return (
        <Card className="text-dark" style={{maxWidth: '600px'}}>
            <Card.Body style={{maxWidth: '100%'}} className="mx-auto px-3 px-md-5">
                {
                    isLoading ? 
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    :
                        <>
                            <Card.Title className="my-2" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>{metadata.name}</Card.Title>
                            <h6 className="mt-2 mb-4" style={{fontWeight: '600'}}>Token #{id}</h6>
                            <div className="border border-dark rounded p-2 bg-dark my-4">
                                <img alt={metadata.name} src={metadata.image} width="500px" style={{maxWidth: '100%'}} className="rounded" />
                            </div>
                            <div>
                                <Card.Title className="mt-4 mb-3 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                                    Owner
                                </Card.Title>
                                <h6 className="text-start mt-2" style={{fontWeight: '600'}}>{_ownerOf} {_ownerOf === address ? '(you are the owner)' : ''}</h6>
                                <p className="text-start" style={{fontSize: '1rem'}}>{metadata.description}</p>
                            </div>
                            <Card.Title className="mt-4 mb-3 text-start" style={{fontWeight: 'bold', fontSize: '1.5rem'}}>
                                Attributes
                            </Card.Title>
                            <Row>
                                {
                                    metadata.attributes && metadata.attributes.length > 0 ? 
                                        metadata.attributes.map((m, i) => (
                                            <Col key={`metadata-trait-${i}`}>
                                                <Card>
                                                    <Card.Body className="bg-light">
                                                        <h6 style={{fontWeight: '600'}}>{m.trait_type}</h6>
                                                        <p className="mb-0" style={{fontSize: '1rem'}}>{m.value}</p>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))
                                    :
                                        <p>No attributes.</p>
                                }
                            </Row>
                            <div className="mt-5 mb-4 text-start">
                                <a href={`https://testnets.opensea.io/assets/rinkeby/${process.env.REACT_APP_CONTRACT_ADDRESS}/${id}`} target="_blank" rel="noreferrer" style={{fontSize: '1rem'}} className="me-3">View on Opensea</a>
                                <a href={`https://rinkeby.etherscan.io/token/${process.env.REACT_APP_CONTRACT_ADDRESS}?a=${id}`} target="_blank" rel="noreferrer" style={{fontSize: '1rem'}}>View on block explorer</a>
                            </div>
                        </>
                }
            </Card.Body>
        </Card>
    );
}
