import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import LinkNav from './components/LinkNav';
import ErrorGate from './components/ErrorGate';
import TokenTemplateCard from './components/TokenTemplateCard';
import LoadingCard from './components/LoadingCard';
import {
    ETH_SEND_TRANSACTION
} from './helpers/constants';
import {
    mintWithNewGroup,
    saleIsActive,
    signFileSignatureToken,
} from './helpers/web3';
import { WalletContext } from './providers/WalletProvider';
import SigningCard from './components/SigningCard';
import ConfirmationCard from './components/ConfirmationCard';
import ProcessingCard from './components/ProcessingCard';
// import PageNav from './components/PageNav';


export default function Mint() {
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSigning, setIsSigning] = useState(false);
    const [signature, setSignature] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [document, setDocument] = useState({});
    const [tokenName, setTokenName] = useState('');
    const [mintPrice, setMintPrice] = useState('');
    
    const { config, setConfig, error, setError, onConnect, resetApp } = useContext(WalletContext);

    useEffect(() => {
        if (config.web3 && config.address !== '') {
            const _fetch = async () => {
                setLoading(true);
                setConfig((oldConfig) => ({...oldConfig, result: null}));

                try {
                    const saleIsActiveResult = await saleIsActive(config.chainId, config.web3);
                    setIsActive(saleIsActiveResult);

                    setError(null);
                }
                catch (err) {
                    console.log('this do be error');
                    console.error(err);
                    setError(err);
                }

                setLoading(false);
            };
            _fetch();
        }
    }, [config.address, config.web3, config.chainId, setConfig, setError]);

    const startMint = (_documentId, _document, _tokenName, _mintPrice) => {
        setIsSigning(true);
        setDocumentId(_documentId);
        setDocument(_document);
        setTokenName(_tokenName);
        setMintPrice(_mintPrice);
        window.scrollTo(0, 0);
    };

    const startFileSignature = async () => {
        const { web3, chainId, ethers } = config;

        if (!web3) {
            return;
        }

        if (!startFileSignature) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: true }));

            const sig = await signFileSignatureToken(tokenName, document, documentId, chainId, web3, ethers);
            setSignature(sig);

            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    const startSendTransaction = async () => {
        const { web3, address, chainId } = config;

        if (!web3 || signature === '') {
            return;
        }

        if (!mintWithNewGroup) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));
            setIsSigning(false);

            const { signature: _signature, result } = await mintWithNewGroup(signature, tokenName, document, documentId, mintPrice, address, chainId, web3);
            console.log('RESULTS', _signature, result);

            const formattedResult = {
                action: ETH_SEND_TRANSACTION,
                result
            };

            setConfig((oldConfig) => ({
                ...oldConfig,
                web3,
                pendingRequest: false,
                result: formattedResult || null
            }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    return (
        <div className="App-header">
            <Container className="mt-3">
                <LinkNav address={config.address} resetApp={resetApp} chainId={config.chainId} />
                {/* <PageNav /> */}
                {
                    config.pendingRequest || loading ? 
                        <ProcessingCard />
                    :
                    <>
                        {
                            error ? <ErrorGate /> :
                                config.address === '' ? 
                                    <div className="mt-5">
                                        <Button variant="primary" onClick={onConnect}>
                                            Connect Wallet
                                        </Button>
                                    </div>
                                :
                                    isActive ? 
                                        isSigning && signature === '' ? 
                                            <SigningCard onClick={startFileSignature} document={document} documentId={documentId} mintPrice={mintPrice} />
                                        :
                                            isSigning && signature !== '' ? 
                                                <ConfirmationCard onClick={startSendTransaction} mintPrice={mintPrice} document={document} signature={signature} />
                                            :
                                                config.result ? 
                                                    <LoadingCard chainId={config.chainId} signature={signature} transactionHash={config.result.result} web3={config.web3} />
                                                :
                                                    <Row className="my-5">
                                                        {
                                                            JSON.parse(process.env.REACT_APP_TEMPLATE_IDS).map((id, i) => (
                                                                <Col md={12} lg={6} key={`token-template-${i}`} className="text-center">
                                                                    <TokenTemplateCard id={id} startSendTransaction={startMint} />
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                    :
                                        <div>
                                            <h6>The token sale is currently closed.</h6>
                                        </div>
                        }
                    </>
                }
            </Container>
        </div>
    );
}
