import React from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/esm/Spinner';


export default function ProcessingCard() {
    return (
        <div className="my-5">
            <Card className="p-4 mx-auto" style={{maxWidth: '600px'}}>
                <Card.Body>
                    <h5 className="text-dark">Transaction Processing...</h5>
                    <div className="my-3">
                        <Spinner animation="border" role="status" size="sm" className="text-dark">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
