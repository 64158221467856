import React from 'react';
import Container from 'react-bootstrap/Container';


export default function Tokens() {
    return (
        <div className="App-header">
            <Container className="mt-3">

            </Container>
        </div>
    );
}

