import React, { useState, useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/esm/Spinner';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Card from 'react-bootstrap/Card';

import { WalletContext } from './providers/WalletProvider';
import { groupsOfOwner, tokensOfGroup } from './helpers/web3';
import LinkNav from './components/LinkNav';
import TokenCard from './components/TokenCard';


export default function MyTokens() {
    const { config, resetApp } = useContext(WalletContext);
    const [tokenIds, setTokenIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const _fetch = async () => {
            try {
                if (config.address !== '') {
                    setIsLoading(true);
                    const groupIds = await groupsOfOwner(config.address, config.chainId, config.web3);
                    console.log('GROUPS', groupIds);
    
                    for (let i = 0; i < groupIds.length; i++) {
                        const _tokenIds = await tokensOfGroup(groupIds[i], config.chainId, config.web3);
                        console.log('TOKEN IDS', _tokenIds);
                        setTokenIds((oldTokenIds) => ([...oldTokenIds, ..._tokenIds]));
                    }
    
                    setIsLoading(false)
                }
            }
            catch(e) {
                console.log('Error!', e);
            }
        };
        _fetch();
    }, [config.address, config.chainId, config.web3]);

    return (
        <div className="App-header">
            <Container className="mt-3 mb-5">
                <LinkNav address={config.address} resetApp={resetApp} chainId={config.chainId} />
                <Card className="my-5">
                    <Card.Header className="bg-light">
                        <Card.Title className="text-dark my-3" style={{fontSize: '2rem', fontWeight: 'bold'}}>My Tokens</Card.Title>
                        <Card.Subtitle className="mb-3 text-dark">You own {tokenIds.length} {process.env.REACT_APP_TOKEN_NAME} ({process.env.REACT_APP_TOKEN_SYMBOL}) tokens.</Card.Subtitle>
                    </Card.Header>
                </Card>
                {
                    isLoading ? 
                        <div className="mt-5">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <h5 className='text-white'>Your transaction is processing...</h5>
                        </div>
                    :
                        <Row className="mt-5">
                            {
                                tokenIds.map((t, i) => (
                                    <Col md={12} lg={6} key={`token-card-${i}`} className="p-3"><TokenCard chainId={config.chainId} web3={config.web3} id={t} key={i} address={config.address} /></Col>
                                ))
                            }
                        </Row>
                }
            </Container>
        </div>
    );
}
