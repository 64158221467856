import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

import CustomNav from './components/CustomNav';
import ErrorGate from './components/ErrorGate';
import { WalletContext } from './providers/WalletProvider';
import { ETH_SEND_TRANSACTION } from './helpers/constants';
import {
    saleIsActive,
    whitelistSaleIsActive,
    flipSalesState,
    flipWhitelistSaleState,
    setWhitelistSigningAddress,
    generateWhitelistSignature,
    maxPremiumPerWallet,
    maxStandardPerWallet,
    allowedPremiumMintCount,
    allowedStandardMintCount,
    mintPremiumReservedToAddress,
    mintStandardReservedToAddress,
    owner,
    standardReservedMintCount,
    premiumReservedMintCount
} from './helpers/web3';

export default function Admin() {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [whitelistStatus, setWhitelistStatus] = useState(false);
    const [whitelistAddress, setWhitelistAddress] = useState('');
    const [standardCount, setStandardCount] = useState(0);
    const [standardAddress, setStandardAddress] = useState('');
    const [premiumCount, setPremiumCount] = useState(0);
    const [premiumAddress, setPremiumAddress] = useState('');
    const [totalPremium, setTotalPremium] = useState(0);
    const [totalStandard, setTotalStandard] = useState(0);
    const [balanceAddress, setBalanceAddress] = useState('');
    const [premiumBalance, setPremiumBalance] = useState(-1);
    const [standardBalance, setStandardBalance] = useState(-1);
    const [premiumReservedBalance, setPremiumReservedBalance] = useState(-1);
    const [standardReservedBalance, setStandardReservedBalance] = useState(-1);
    const [contractOwner, setContractOwner] = useState('');

    const { config, setConfig, error, setError, onConnect, resetApp } = useContext(WalletContext);

    useEffect(() => {
        if (config.web3 && config.address !== '') {
            const _fetch = async () => {
                setLoading(true);
                setConfig((oldConfig) => ({...oldConfig, result: null}));

                try {
                    const isActiveResult = await saleIsActive(config.chainId, config.web3);
                    setStatus(isActiveResult);

                    const isWhitelistActive = await whitelistSaleIsActive(config.chainId, config.web3);
                    setWhitelistStatus(isWhitelistActive);

                    const maxPremiumPerWalletResult = await maxPremiumPerWallet(config.chainId, config.web3);
                    setTotalPremium(parseInt(maxPremiumPerWalletResult));

                    const maxStandardPerWalletResult = await maxStandardPerWallet(config.chainId, config.web3);
                    setTotalStandard(parseInt(maxStandardPerWalletResult));

                    const ownerOfResult = await owner(config.chainId, config.web3);
                    setContractOwner(ownerOfResult);

                    setError(null);
                }
                catch (err) {
                    console.log('this do be error');
                    console.error(err);
                    setError(err);
                }

                setLoading(false);
            };
            _fetch();
        }
    }, [config.address, config.web3, config.chainId, setConfig, setError]);

    const startBalance = async () => {
        const { web3, chainId } = config;

        if (!web3) {
            return;
        }

        if (!allowedPremiumMintCount || !allowedStandardMintCount || !maxPremiumPerWallet || !maxStandardPerWallet) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));

            const allowedPremiumResult = await allowedPremiumMintCount(balanceAddress, chainId, web3);
            const allowedStandardResult = await allowedStandardMintCount(balanceAddress, chainId, web3);
            const premiumReservedBalanceResult = await premiumReservedMintCount(balanceAddress, chainId, web3);
            const standardReservedBalanceResult = await standardReservedMintCount(balanceAddress, chainId, web3);

            console.log('results', allowedPremiumResult, allowedStandardResult);

            setPremiumBalance(parseInt(totalPremium) - parseInt(allowedPremiumResult));
            setStandardBalance(parseInt(totalStandard) - parseInt(allowedStandardResult));
            setPremiumReservedBalance(parseInt(premiumReservedBalanceResult));
            setStandardReservedBalance(parseInt(standardReservedBalanceResult));

            setConfig((oldConfig) => ({...oldConfig, pendingRequest: false }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    const startFlipSalesState = async (theType) => {
        const { web3, address, chainId } = config;

        if (!web3) {
            return;
        }

        if (!flipSalesState || !flipWhitelistSaleState) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));

            let result;
            if (theType === 'sales') {
                result = await flipSalesState(address, chainId, web3);
            }
            else if (theType === 'whitelist') {
                result = await flipWhitelistSaleState(address, chainId, web3);
            }

            const formattedResult = {
                action: ETH_SEND_TRANSACTION,
                result
            };

            setConfig((oldConfig) => ({
                ...oldConfig,
                web3,
                pendingRequest: false,
                result: formattedResult || null
            }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    const startSetWhitelistSigner = async () => {
        const { web3, address, chainId } = config;

        if (!web3) {
            return;
        }

        if (!setWhitelistSigningAddress) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));

            const result = await setWhitelistSigningAddress(address, chainId, web3);

            const formattedResult = {
                action: ETH_SEND_TRANSACTION,
                result
            };

            setConfig((oldConfig) => ({
                ...oldConfig,
                web3,
                pendingRequest: false,
                result: formattedResult || null
            }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    const startGenerateWhitelistSignature = async () => {
        const { ethers, chainId } = config;

        if (!ethers) {
            return;
        }

        if (!generateWhitelistSignature) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));

            const result = await generateWhitelistSignature(whitelistAddress, chainId, ethers);

            const formattedResult = {
                action: ETH_SEND_TRANSACTION,
                result
            };

            console.log('SIG RESPONSE', formattedResult);
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, ethers, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    const startMintReserved = async (theType) => {
        const { web3, address, chainId } = config;

        if (!web3) {
            return;
        }

        if (!mintPremiumReservedToAddress || !mintStandardReservedToAddress) {
            throw new Error('Missing matching contract calls');
        }

        try {
            setConfig((oldConfig) => ({...oldConfig, pendingRequest: true }));

            let result;
            if (theType === 'premium') {
                result = await mintPremiumReservedToAddress(premiumCount, premiumAddress, address, chainId, web3);
            }
            else if (theType === 'standard') {
                result = await mintStandardReservedToAddress(standardCount, standardAddress, address, chainId, web3);
            }

            const formattedResult = {
                action: ETH_SEND_TRANSACTION,
                result
            };

            setConfig((oldConfig) => ({
                ...oldConfig,
                web3,
                pendingRequest: false,
                result: formattedResult || null
            }));
        }
        catch (err) {
            console.error(err);
            setConfig((oldConfig) => ({ ...oldConfig, web3, pendingRequest: false, result: null }));
            setError(err);
        }
    };

    return (
        <div className="App-header">
            <Container className="mt-3">
                <div>
                    {
                        config.address !== '' ? <div><Button onClick={resetApp} variant="link">Reset connection</Button></div> : null
                    }
                    <Link className="fs-6 mb-3 d-inline-block" to="/login">Login</Link>
                    <h6 style={{fontWeight: '700'}}>Your Address</h6>
                    <h6>{config.address === '' ? 'Connect your wallet to get started.' : config.address}</h6>
                </div>
                <CustomNav theKey="admin" />
                {
                    config.pendingRequest || loading ? 
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    :
                    <>
                        {
                            error ? <ErrorGate /> :
                                config.address === '' ? 
                                    <div className="mt-5">
                                        <Button variant="primary" onClick={onConnect}>
                                            Connect Wallet
                                        </Button>
                                    </div>
                                :
                                    config.result ? 
                                        <>
                                            <h6 style={{fontWeight: 700}}>You're on your way! Here is your transaction reciept...</h6>
                                            <h6><a href={`https://rinkeby.etherscan.io/tx/${config.result.result.transactionHash}`} rel="noreferrer" target="_blank">{config.result.result.transactionHash}</a></h6>
                                        </>
                                    :
                                        <>
                                            <div className="my-5">
                                                <h6 style={{fontWeight: '700'}}>Conctract Address</h6>
                                                <h5>{process.env.REACT_APP_CONTRACT_ADDRESS}</h5>
                                            </div>
                                            <div className="my-5">
                                                <h6 style={{fontWeight: '700'}}>Conctract Owner</h6>
                                                <h5>{contractOwner}</h5>
                                                <h6>{contractOwner === config.address ? 'You are the owner.' : (<span className="text-danger">You are not the owner.</span>) }</h6>
                                            </div>
                                            <div className="my-5">
                                                <h6 style={{fontWeight: '700'}}>Sales Status</h6>
                                                <h6>Current sales status:  <span style={{fontWeight: '700'}}>{status ? 'Live' : 'Off'}</span></h6>
                                                <h6>Current whitelist sales status: <span style={{fontWeight: '700'}}>{whitelistStatus ? 'Live' : 'Off'}</span></h6>
                                                <Button onClick={() => startFlipSalesState('sales')} className="m-3">
                                                    Change sales status
                                                </Button>
                                                <Button onClick={() => startFlipSalesState('whitelist')} className="m-3">
                                                    Change whitelist sales status
                                                </Button>
                                            </div>
                                            <div className="my-5">
                                                <h6 style={{fontWeight: '700'}}>Check Balance Of Wallet</h6>
                                                <div className="mx-auto" style={{maxWidth: '600px'}}>
                                                    <Form.Control className="my-3" placeholder="Address" type="text" value={balanceAddress} onChange={(e) => setBalanceAddress(e.target.value)} />
                                                    {
                                                        premiumBalance > -1 ? <h6>Premium tokens: <b>{premiumBalance}</b></h6> : null
                                                    }
                                                    {
                                                        standardBalance > -1 ? <h6>Standard tokens: <b>{standardBalance}</b></h6> : null
                                                    }
                                                    {
                                                        premiumReservedBalance > -1 ? <h6>Premium reserved tokens: <b>{premiumReservedBalance}</b></h6> : null
                                                    }
                                                    {
                                                        standardReservedBalance > -1 ? <h6>Standard reserved tokens: <b>{standardReservedBalance}</b></h6> : null
                                                    }
                                                    <Button onClick={startBalance} className="m-3">
                                                        Get balance
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="my-5">
                                                <h6 style={{fontWeight: '700'}}>Manage Whitelist</h6>
                                                <Button onClick={startSetWhitelistSigner} className="m-3">
                                                    Set signing address
                                                </Button>
                                            </div>
                                            <div className="my-5">
                                                <h6 style={{fontWeight: '700'}}>Generate Whitelist Signature For Wallet</h6>
                                                <div className="mx-auto" style={{maxWidth: '600px'}}>
                                                    <Form.Control className="my-3" placeholder="Address" type="text" value={whitelistAddress} onChange={(e) => setWhitelistAddress(e.target.value)} />
                                                    <Button onClick={startGenerateWhitelistSignature} className="m-3">
                                                        Generate
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="my-5">
                                                <h6 style={{fontWeight: '700'}}>Mint Premium Reserved To Wallet</h6>
                                                <div className="mx-auto" style={{maxWidth: '600px'}}>
                                                    <Form.Control type="text" placeholder="Address" className="my-3" value={premiumAddress} onChange={(e) => setPremiumAddress(e.target.value)} />
                                                    <div className="my-3">
                                                        <Button disabled={premiumCount >= totalPremium ? true : false} onClick={() => setPremiumCount(premiumCount + 1)}>
                                                            +
                                                        </Button>
                                                        <span className="px-5">{premiumCount}</span>
                                                        <Button disabled={premiumCount <= 0 ? true : false} onClick={() => setPremiumCount(premiumCount - 1)}>
                                                            -
                                                        </Button>
                                                    </div>
                                                    <Button disabled={premiumCount > 0 ? false : true} onClick={() => startMintReserved('premium')} className="m-3">
                                                        Mint
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="my-5">
                                                <h6 style={{fontWeight: '700'}}>Mint Standard Reserved To Wallet</h6>
                                                <div className="mx-auto" style={{maxWidth: '600px'}}>
                                                    <Form.Control  className="my-3" placeholder="Address" type="text" value={standardAddress} onChange={(e) => setStandardAddress(e.target.value)} />
                                                    <div className="my-3">
                                                        <Button disabled={standardCount >= totalStandard ? true : false} onClick={() => setStandardCount(standardCount + 1)}>
                                                            +
                                                        </Button>
                                                        <span className="px-5">{standardCount}</span>
                                                        <Button disabled={standardCount <= 0 ? true : false} onClick={() => setStandardCount(standardCount - 1)}>
                                                            -
                                                        </Button>
                                                    </div>
                                                    <Button disabled={standardCount > 0 ? false : true} onClick={() => startMintReserved('standard')} className="m-3">
                                                        Mint
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                        }
                    </>
                }
            </Container>
        </div>
    );
}
