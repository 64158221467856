import React from 'react';
import './App.css';

import { WalletProvider } from './providers/WalletProvider';
import { AuthProvider } from './providers/AuthProvider';
import CustomRoutes from './CustomRoutes';


function App() {
  return (
    <div className="App">
      <WalletProvider>
        <AuthProvider>
          <CustomRoutes />
        </AuthProvider>
      </WalletProvider>
    </div>
  );
}

export default App;
