import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';

import { auth } from './helpers/base';


export default function Register() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const startRegister = async () => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            navigate('/app/tokens');
        }
        catch (err) {
            console.error(err);
            alert(err);
        }
    };

    return (
        <div className="App-header">
            <Container className="mt-5">
                <Card style={{maxWidth: '500px'}} className="mx-auto">
                    <Card.Header>
                        <Card.Title className="fw-bold text-dark text-start m-0 py-2">Register</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group className="mb-3 text-start text-body fs-6" controlId="login.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3 text-start text-body fs-6" controlId="login.ControlInput2">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3 text-start text-body fs-6" controlId="login.ControlInput3">
                                <Form.Label>Password confirmation</Form.Label>
                                <Form.Control type="password" placeholder="********" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)}/>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                    <Card.Footer className="text-end">
                        <Button className="mx-3" variant="link" onClick={() => navigate('/app/login')}>
                            Login
                        </Button>
                        <Button className="my-2" onClick={() => startRegister()}>
                            Continue
                        </Button>
                    </Card.Footer>
                </Card>
            </Container>
        </div>
    );
}
