import BigNumber from 'bignumber.js';

import { CONTRACT } from './contracts';
import { apiGetGasPrices, apiGetAccountNonce } from "./api";
import { convertAmountToRawNumber, convertStringToHex } from "./bignumber";
import { sanitizeHex } from './utilities';

export function getContract(chainId, web3) {
    const contract = new web3.eth.Contract(CONTRACT[chainId].abi, CONTRACT[chainId].address);
    return contract;
}

export function totalTokens(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .totalTokens()
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function maxSupply(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .MAX_SUPPLY()
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function mintPrice(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .MINT_PRICE()
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function maxStandard(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .MAX_STANDARD_SUPPLY()
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function maxPremium(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .MAX_PREMIUM_SUPPLY()
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function maxStandardPerWallet(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        await contract.methods
        .MAX_STANDARD_PER_WALLET()
        .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
            if (err) {
                reject(err);
            }
            
            resolve(data);
        });
    });
}

export function maxPremiumPerWallet(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        await contract.methods
        .MAX_PREMIUM_PER_WALLET()
        .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
            if (err) {
                reject(err);
            }
            
            resolve(data);
        });
    });
}

export function balanceOf(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        await contract.methods
        .balanceOf(address)
        .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
            if (err) {
                reject(err);
            }
            
            resolve(data);
        });
    });
}

export function allowedPremiumMintCount(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const data = await contract.methods
            .allowedPremiumMintCount(address)
            .call({ from: '0x0000000000000000000000000000000000000000' });
            
            resolve(data);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function allowedStandardMintCount(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        await contract.methods
        .allowedStandardMintCount(address)
        .call({ from: '0x0000000000000000000000000000000000000000' }, (err, data) => {
            if (err) {
                reject(err);
            }
            
            resolve(data);
        });
    });
}

export function mintPremium(address, chainId, web3, count) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 500000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            let _value = BigNumber('69000000000000000');
            _value = _value.times(count).toString();
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .mintPremium(`${count}`)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function mintStandard(address, chainId, web3, count) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 500000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .mintStandard(`${count}`)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function signFileSignatureToken(signingName, signingMetadata, signingDataId, chainId, web3, ethers = null) {
    return new Promise(async (resolve, reject) => {
        const domain = {
            name: 'FileSignatureToken',
            version: '1',
            chainId,
            verifyingContract: CONTRACT[chainId].address,
        };
      
        const types = {
            Owner: [
                { name: 'name', type: 'string' },
                { name: 'metadataHash', type: 'string' },
                { name: 'dataId', type: 'string' }
            ],
        };

        try {
            const signer = ethers.getSigner();
            const metadataHash = web3.utils.soliditySha3(JSON.stringify(signingMetadata));
            const sig = await signer._signTypedData(domain, types, { name: signingName, metadataHash, dataId: signingDataId });
            resolve(sig);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function mintWithNewGroup(signature, signingName, signingMetadata, signingDataId, __value, address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const metadataHash = web3.utils.soliditySha3(JSON.stringify(signingMetadata));

            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 500000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = __value;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            // const result = await 
            contract.methods
            .mintWithNewGroup([1], signingName, metadataHash, signingDataId, signature)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            })
            .on('transactionHash', (hash) => resolve({ signature, result: hash }))
            .on('error', (err) => reject(err));
        }
        catch (err) {
            reject(err);
        }
    });
}

export function saleIsActive(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .saleIsActive()
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function whitelistSaleIsActive(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .whitelistSaleIsActive()
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function flipSaleIsActive(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 80000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .flipSaleIsActive()
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function flipSalesState(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 80000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .flipSaleState()
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function flipWhitelistSaleState(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 80000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .flipWhitelistSaleState()
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function setWhitelistSigningAddress(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 80000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .setWhitelistSigningAddress(address)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function setAdmin(owner, address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 80000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .setAdmin(owner)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function generateWhitelistSignature(owner, chainId, ethers) {
    return new Promise(async (resolve, reject) => {
        console.log('contract address', process.env.REACT_APP_CONTRACT_ADDRESS);

        const domain = {
            name: "WhitelistToken",
            version: "1",
            chainId,
            verifyingContract: process.env.REACT_APP_CONTRACT_ADDRESS
        };
        
        const types = {
            Minter: [{ name: "wallet", type: "address" }]
        };

        try {
            const signer = ethers.getSigner();
            const sig = await signer._signTypedData(domain, types, {
                wallet: owner.toLowerCase()
            });
            resolve(sig);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function mintStandardReservedToAddress(count, owner, address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 500000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .mintStandardReservedToAddress(`${count}`, owner)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function mintPremiumReservedToAddress(count, owner, address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 500000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .mintPremiumReservedToAddress(`${count}`, owner)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function mintStandardWhitelist(count, signature, address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 500000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            const _value = 0;
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .mintStandardWhitelist(`${count}`, signature)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function mintPremiumWhitelist(count, signature, address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const _nonce = await apiGetAccountNonce(address, chainId);
            const nonce = sanitizeHex(convertStringToHex(_nonce));

            const gasPrices = await apiGetGasPrices();
            const _gasPrice = gasPrices.slow.price;
            const gasPrice = sanitizeHex(
                convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
            );

            const _gasLimit = 500000;
            const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
            
            let _value = BigNumber('69000000000000000');
            _value = _value.times(count).toString();
            const value = sanitizeHex(convertStringToHex(_value));

            const data = "0x";
    
            const result = await contract.methods
            .mintPremiumWhitelist(`${count}`, signature)
            .send({
                from: address,
                value,
                gasPrice,
                gasLimit,
                nonce,
                data
            });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function standardReservedMintCount(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .standardReservedMintCount(address)
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function premiumReservedMintCount(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .premiumReservedMintCount(address)
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function checkWhitelist(signature, address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .checkWhitelist(signature)
            .call({ from: address });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function owner(chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .owner()
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function groupsOfOwner(address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .groupsOfOwner(address)
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function tokensOfGroup(groupId, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .tokensOfGroup(`${groupId}`)
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function tokenURI(tokenId, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .tokenURI(`${tokenId}`)
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function ownerOf(tokenId, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.methods
            .ownerOf(`${tokenId}`)
            .call({ from: '0x0000000000000000000000000000000000000000' });
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function getPastEvents(signature, blockNumber, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const result = await contract.getPastEvents('HasMinted', {
                filter: {
                    _signature: signature,
                },
                fromBlock: blockNumber,
                toBlock: 'latest',
            });       
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    });
}

export function verifyTokenFileSigner(signingName, signingMetadata, signingDataId, signature, address, chainId, web3) {
    return new Promise(async (resolve, reject) => {
        const contract = getContract(chainId, web3);

        try {
            const metadataHash = web3.utils.soliditySha3(JSON.stringify(signingMetadata));

            const result = await contract.methods
            .verifyTokenFileSigner(signingName, metadataHash, signingDataId, signature)
            .call({ from: address });
            resolve(result);
        }
        catch (err) {
            reject(false);
        }
    });
}
