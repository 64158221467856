import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { AuthContext } from './providers/AuthProvider';
import Mint from './Mint';
import Admin from './Admin';
import Whitelist from './Whitelist';
import Login from './Login';
import Register from './Register';
import Tokens from './Tokens';
import MyTokens from './MyTokens';
import Verify from './Verify';


export default function CustomRoutes() {
    const { currentUser } = useContext(AuthContext);

    useEffect(() => console.log('current user', currentUser));

    return (
      <Router>
        <Routes>
          <Route path="/app/tokens" element={!!currentUser ? <Tokens /> : <Navigate to="/app/login" />} />
          <Route path="/app/register" element={!currentUser ? <Register /> : <Navigate to="/app/tokens" />} />
          <Route path="/app/login" element={!currentUser ? <Login /> : <Navigate to="/app/tokens" />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/whitelist" element={<Whitelist />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/my-tokens" element={<MyTokens />} />
          <Route index element={<Mint />} />
        </Routes>
      </Router>
    );
}
